<template>
  <div class="login-panel">
    <div class="login">
      <a-tabs size="large" @change="callback" :tabBarStyle="{ textAlign: 'center' }" style="padding: 4px 2px;">
        <a-tab-pane tab="账户密码登录" key="1">
          <a-form-model ref="ruleForm" :model="formModel" :rules="rules1">
            <a-form-model-item prop="userName">
              <a-input autocomplete="off" size="large" v-model="formModel.userName" placeholder="请输入账户名">
                <a-icon slot="prefix" type="user" />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="password">
              <a-input autocomplete="off" size="large" v-model="formModel.password" placeholder="请输入密码" type="password">
                <a-icon slot="prefix" type="lock" />
              </a-input>
            </a-form-model-item>
            <div style="height: 1rem">
              <a-checkbox v-model="formModel.checkedUser">记住用户名密码</a-checkbox>
            </div>
            <a-form-model-item v-if="showBtn">
              <a-button :loading="logging" style="width: 100%; margin-top: 24px" size="large" type="primary"
                @click="onSubmit">
                登录
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>

        <a-tab-pane tab="手机号登录" key="2">
          <a-form-model ref="ruleForm2" :model="formModel" :rules="rules2">
            <a-form-model ref="ruleForm3" :model="formModel" :rules="rules3">
              <a-form-model-item prop="mobile">
                <a-input size="large" placeholder="请输入手机号" v-model="formModel.mobile">
                  <a-icon slot="prefix" type="mobile" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item v-show="imgCodeVisible" prop="imgCode">
                <a-row :gutter="8" style="margin: 0 -4px">
                  <a-col :span="14">
                    <a-input size="large" placeholder="请输入图形验证码" v-model="formModel.imgCode">
                      <a-icon slot="prefix" type="safety" />
                    </a-input>
                  </a-col>
                  <a-col :span="10" style="padding-left: 4px">
                    <a-button size="large" @click="getImgCode" class="imgbtn">
                      <img class="imgcode" :src="imgcodeSrc" />
                    </a-button>
                  </a-col>
                </a-row>
              </a-form-model-item>
              <a-form-model-item prop="yzm">
                <a-row :gutter="8" style="margin: 0 -4px">
                  <a-col :span="14">
                    <a-input size="large" placeholder="请输入手机验证码" v-model="formModel.yzm">
                      <a-icon slot="prefix" type="mail" />
                    </a-input>
                  </a-col>
                  <a-col :span="10" style="padding-left: 4px">
                    <a-button :loading="logdingYzm" style="width: 100%" class="captcha-button" size="large"
                      :disabled="isDisable" type="primary" @click="getCode">
                      {{ getYzmText }}
                    </a-button>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-form-model>
            <a-form-model-item v-if="showBtn">
              <a-button :loading="logging" style="width: 100%; margin-top: 24px" size="large" type="primary"
                @click="onSubmit2">
                登录
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import createUniqueString from "@/utils/createUniqueString";
import { getyzm, getImgCode } from "@/api";
import { mapState, mapMutations } from "vuex";
import { getUserProteCD } from "../../common/commonGlobal";

export default {
  name: "loginForm",
  props: {
    showBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      //提交表单 集合
      formModel: {
        userName: "", //用户名
        password: "", //密码
        checkedUser: false, //是否记住用户名密码
        mobile: "", //手机号
        imgCode: "", //模块类型
        yzm: "", //手机验证码
      },
      // form: this.$form.createForm(this, {}),
      yzmtext: "获取验证码", //验证码按钮内容
      interval: null, //计时器
      logdingYzm: false,
      logging: false,

      key: "1", //1账户密码登录，2手机号登录
      imgcodeSrc: "", //图形验证码路径
      imgCodeVisible: false, //图形验证码是否显示

      //账户密码登录 验证
      rules1: {
        userName: [
          { required: true, message: "请输入账户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", whitespace: true }],
      },
      //手机号登录 验证
      rules2: {
        mobile: [
          { required: true, message: "请输入手机号", whitespace: true },
          { validator: this.phoneCheck.bind(this) },
        ],
        imgCode: [
          { required: true, message: "请输入图形验证码", whitespace: true },
        ],
        yzm: [
          { required: true, message: "请输入手机验证码", whitespace: true },
        ],
      },

      //获取验证码 验证
      rules3: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: this.phoneCheck.bind(this) },
        ],
      },
    };
  },
  computed: {
    ...mapState("user", ["showRelogin"]),
    getYzmText() {
      return this.yzmtext.toString() + (!isNaN(this.yzmtext) ? "s" : "");
    },
    isDisable() {
      return this.yzmtext.toString().indexOf("获取") < 0;
    },
  },

  mounted() {
    //记住密码时，读取localStorage里用户名密码
    if (localStorage.getItem("userName")) {
      this.formModel.userName = localStorage.getItem("userName");
      // this.formModel.password = decryptByDESModeECB(localStorage.getItem('password'), "0CoJUm6Qyw8W8jud");
      this.formModel.password = localStorage.getItem("password");
      this.formModel.checkedUser = true;
    }
  },
  methods: {
    //tabs 切换
    callback(key) {
      this.key = key;
    },

    //重新获取 图形验证码
    // reloadImg() {
    //   this.imgcodeSrc =
    //     "/basedata/usercenter/comm/imgcode/query?rand=" + createUniqueString();
    // },
    //重新获取 图形验证码
    getImgCode() {
      let params = {
        phone: this.formModel.mobile
      }
      getImgCode(params).then(res => {
        this.imgcodeSrc = res;
      })
    },
    //获取 手机验证码
    getCode() {
      let _this = this;
      if (this.imgCodeVisible) {
        if (!this.formModel.imgCode) {
          this.alertWarning('请先填写图形验证码')
          return
        }
      }
      this.$refs.ruleForm3.validate((valid) => {
        if (valid) {
          //获取验证码
          _this.logdingYzm = true;
          getyzm({
            phone: _this.formModel.mobile,
            imgcode: _this.formModel.imgCode,
          }).then((res) => {
            if (res === "0000000205") {
              // 需要传图形验证码
              // _this.imgcodeSrc = "/basedata/usercenter/comm/imgcode/query";
              _this.getImgCode();
              _this.alertWarning('请先填写图形验证码')
              setTimeout(() => {
                _this.imgCodeVisible = true;
                _this.logdingYzm = false;
              }, 300);
            } else {
              _this.logdingYzm = false;
              //处理倒计时逻辑
              _this.yzmtext = 60;
              _this.interval = setInterval(() => {
                if (_this.yzmtext <= 0) {
                  _this.yzmtext = "重新获取";
                  clearInterval(_this.interval);
                } else {
                  _this.yzmtext--;
                }
              }, 1000);
            }
          },()=>{
            _this.logdingYzm = false
          }).catch(()=>{
             _this.logdingYzm = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //供父组件调用
    login() {
      if (this.key == "1") {
        this.onSubmit();
      } else {
        this.onSubmit2();
      }
    },
    //提交 账户密码登录
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.dataSubmit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //提交 手机号登录
    onSubmit2() {
      this.$refs.ruleForm2.validate((valid) => {
        if (valid) {
          this.dataSubmit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //登录 提交
    dataSubmit() {
      let _that = this;
      this.logging = true;
      let params = {
        account:
          this.key === "1" ? this.formModel.userName : this.formModel.mobile,
        pwd: this.formModel.password,
        code: this.formModel.yzm,
        type: this.key,
        userProteCD: getUserProteCD(),
      };
      this.$store
        .dispatch("user/login", params)
        .then(() => {
          _that.logging = false;

          //记住用户名密码
          localStorage.setItem("userName", params.account);
          if (_that.formModel.checkedUser === true) {
            localStorage.setItem("password", params.pwd);
          } else {
            localStorage.removeItem("password");
          }
          //缓存登录页地址，保留参数
          localStorage.setItem('loginUrl',location.href)
          //执行跳转
          _that.$router.push("/home/").catch(() => { });
        })
        .catch((e) => {
          _that.logging = false;
        });
    },

    //验证手机号
    phoneCheck(rule, value, callbackFn) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(value)) {
        callbackFn("请输入正确的手机号码");
        return;
      }
      callbackFn();
    },
    alertWarning(info) {
      this.$message.warning(info)
    }
  },
};
</script>

<style lang="scss" scoped>
.login-panel {
  width: 400px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px;
  // height: 300px;

  .login {
    width: 368px;
    margin: 0 auto;

    @media screen and (max-width: 576px) {
      width: 95%;
    }

    @media screen and (max-width: 320px) {
      .captcha-button {
        font-size: 14px;
      }
    }

    .icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }

  .imgbtn {
    padding: 0 4px;
  }

  .imgcode {
    width: 100%;
    height: 38px;
  }
}
</style>
