<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: Seven
 * @Date: 2022-07-22 09:06:34
 * @LastEditors: Seven
 * @LastEditTime: 2023-02-10 13:42:49
-->
<template>
  <common-layout>
    <div class="top">
      <div class="header">
        <img alt="logo" class="logo" src="@/assets/img/logo1.png" />
      </div>
    </div>
    <div class="middle">
      <div class="product"></div>
      <div class="right-panel">
        <div class="title">数智健康管理服务平台</div>
        <loginForm></loginForm>
      </div>
    </div>
  </common-layout>
</template>

<script>
import CommonLayout from "@/layouts/CommonLayout";
import loginForm from "./loginForm";

export default {
  name: "login",
  components: {
    CommonLayout,
    loginForm,
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.common-layout {
  .top {
    margin-bottom: 85px;
    .header {
      a {
        text-decoration: none;
      }

      .logo {
        height: 36px;
        vertical-align: top;
        margin-right: 50px;
        margin-left: 27px;
      }

      .title {
        font-size: 33px;
        color: rgba(0, 0, 0, 0.85);
        font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
          sans-serif;
        font-weight: 600;
        position: relative;
        top: 2px;
      }
    }

    .desc {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }
  .middle {
    display: flex;
    justify-content: space-between;
    .product {
      width: 528px;
      height: 356px;
      overflow: auto;
      background-image: url("../../assets/img/product.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .right-panel {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 31px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
