<template>
  <div class="common-layout">
    <div class="content"><slot></slot></div>
    <page-footer link-list="" copyright="亿康科技"></page-footer>
  </div>
</template>

<script>
import PageFooter from "@/layouts/footer/PageFooter";
import { mapState } from "vuex";

export default {
  name: "CommonLayout",
  components: { PageFooter },
};
</script>

<style scoped lang="scss">
.common-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background-color: #f0f2f5;
  background-image: url("../assets/img/login-bg.png");
  background-repeat: no-repeat;
  // background-position-x: center;
  // background-position-y: 110px;
  background-size: 100% 100%;
  align-items: center;
  .content {
    padding: 32px 0;
    width: 1000px;
    @media (min-width: 768px) {
      padding: 25px 0 24px;
    }
  }
}
</style>
